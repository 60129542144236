// @see
// https://github.com/DrupalizeMe/react-and-drupal-examples/tree/master/react-decoupled
// @see https://dev.acquia.com/blog/decoupled-drupal-authentication-oauth-20
export const propTypesSaleOpts = ['All Listings', 'Condominium', 'Income Property', 'Multi-Family', 'Single Family', 'Townhouse', 'Vacant Land', 'Villa'];
export const propTypesRentOpts = ['All Listings', 'Condominium', 'Multi-Family', 'Single Family', 'Townhouse', 'Villa'];
import {resetDefaultValues as RentalSearchDefault} from '../pages/rent/homes-for-rent';
import {resetDefaultValues as BuySearchDefault} from '../pages/buy/homes-for-sale';

/**
 * OpenSolr client factory.
 *
 * @param {object} config
 *
 * @returns {object}
 *   Returns an object of functions with $config injected.
 */
export function getSolrClient(config = {}) {
  let map_vals = {
    txt: 't',
    prop_type: 'pt',
    price_min: 'pl',
    price_max: 'ph',
    acres_min: 'aal',
    acres_max: 'aah',
    sqft_min: 'afl',
    sqft_max: 'afh',
    city: 'c',
    neighbor: 'n',
    garage_min: 'gl',
    garage_max: 'gh',
    beds: 'bed',
    baths: 'bath',
    pool: 'p',
    comm_pool: 'cp',
    waterfront: 'w',
    fireplace: 'f',
    featured: 'b',
    available: 'a',
    openhouse: 'oh',
    myfavorite: 'fav',
    new_build: 'nb',
    market_recent: 'new',
    sort: 'sort',
    start: 'start'
  };
  let sort_vals = {
    its_eaton: 'feat',
    its_price: 'price',
    its_sqft: 'sqft',
  };

  /**
   * Assemble SHARE URL from provided "new_vals" as Object.
   *
   * Object keys are variable names that will be matched into SOLR type field names,
   * e.g. "price_min, prop_type, waterfront, etc.".
   */
  function assembleSearchShareQuery(new_vals, router) {
    let urlQueryArr = [], urlQueryStr = '';

    Object.keys(map_vals).map(k => {
      if (new_vals[k] && new_vals[k] !== 'all') {
        if (['price_min', 'acres_min', 'sqft_min', 'garage_min', 'beds', 'baths'].includes(k)
          && new_vals[k] != 0 && !isNaN(new_vals[k])) {
          urlQueryArr.push(`${map_vals[k]}=${new_vals[k]}`);
        }
        if (['price_max', 'acres_max', 'sqft_max', 'garage_max'].includes(k)
          && new_vals[k] != 0 && !isNaN(new_vals[k])) {
          if (new_vals['type'] == 'Buy' && new_vals[k] != BuySearchDefault[k]) {
            urlQueryArr.push(`${map_vals[k]}=${new_vals[k]}`);
          }
          else if (new_vals['type'] == 'Rental' && new_vals[k] != RentalSearchDefault[k]) {
            urlQueryArr.push(`${map_vals[k]}=${new_vals[k]}`);
          }
        }
        if (['openhouse', 'pool', 'comm_pool', 'market_recent', 'fireplace', 'waterfront', 'new_build', 'featured', 'available'].includes(k)
          && new_vals[k]) {
          urlQueryArr.push(`${map_vals[k]}=1`);
        }
        if (['city', 'neighbor'].includes(k) && new_vals[k].length > 0 && new_vals[k][0] != 'all') {
          urlQueryArr.push(`${map_vals[k]}=${new_vals[k].join(',')}`);
        }
        if (k == 'txt' && new_vals[k].length > 0) {
          urlQueryArr.push(`${map_vals[k]}=${encodeURI(new_vals[k])}`);
        }
        if (k == 'prop_type' && new_vals[k].length > 0 && new_vals[k][0] != 0) {
          urlQueryArr.push(`${map_vals[k]}=${new_vals[k].join(',')}`);
        }
        if (k == 'sort' && new_vals[k].length > 0 && new_vals[k][0].field) {
          urlQueryArr.push(`sort=${new_vals[k][0].dir}_${sort_vals[new_vals[k][0].field]}`);
        }
      }
    });

    if (urlQueryArr?.length > 0) {
      urlQueryStr = `${router.basePath}${router.pathname}?${urlQueryArr.join('&')}`;
    }

    return urlQueryStr;
  }

  /**
   * Assemble Solr query as a string from provided "new_vals" as Object.
   *
   * Object keys are variable names that will be matched into SOLR type field names,
   * e.g. "price_min, prop_type, waterfront, etc.".
   */
  function assembleQuery(new_vals) {
    let res = '', min_p = '', max_p = '', defaultValues = {};
    let queryStringQ, queryStringFq = '', queryStringFl = '&fl=*',
      queryStringQF = '', sortString = '&sort=', queryStringFacet = '', vals;
    if (new_vals?.solrMltListings) {
      defaultValues = {
        index_id: 'eat_listings',
        internalId: '',
        index_hash: '5cwkwn',
        content_type: 'listing',
        skip_sold: true,
        content: 'entity:node/',
        lang: 'en',
        mlt_fields: `ss_sale_type,tm_X3b_en_city`,
        sort: [
          {dir: 'DESC', field: 'score'},
          // {dir: 'DESC', field: 'ds_modified'}
        ],
        start: 0,
        ppage: 1,
      };
      vals = {...defaultValues, ...new_vals};
      queryStringQ = `&mlt.fl=${vals.mlt_fields}&q=id:%22${vals.index_hash}-${vals.index_id}-${vals.content}${vals.internalId}:${vals.lang}%22`;
      if (new_vals?.spatialBoost) {
        // https://solr.apache.org/guide/7_5/spatial-search.html#boost-nearest-results
        queryStringQ += `&fq={!geofilt}&sfield=rpts_coords&pt=${new_vals.spatialBoost}&d=100&bf=recip(geodist(),2,200,20)`;
      }
    }
    else {
      if (new_vals?.solrBoundaryPages) {
        defaultValues = {
          index_id: 'boundary_terms',
          content_type: 'boundary_page',
          sort: [
            {dir: 'ASC', field: 'ss_boundary_type'},
            {dir: 'DESC', field: 'ds_modified'},
          ],
          start: 0,
          ppage: 1,
        };
      }
      else {
        defaultValues = {
          index_id: 'eat_listings',
          content_type: 'listing',
          skip_sold: true,
          txt: '',
          type: '',
          prop_type: [0],
          price_min: 0,
          price_max: 0,
          acres_min: 0,
          acres_max: 0,
          sqft_min: 0,
          sqft_max: 0,
          city: ['all'],
          neighbor: ['all'],
          schools: ['all'],
          beds: 0,
          baths: 0,
          garage_min: 0,
          garage_max: 0,
          pool: false,
          comm_pool: false,
          market_recent: false,
          fireplace: false,
          waterfront: false,
          new_build: false,
          featured: false,
          available: false,
          available_min: null,
          available_max: null,
          sort: [{dir: 'DESC', field: 'ds_modified'}],
          start: 0,
          ppage: 1,
        };
      }
      vals = {...defaultValues, ...new_vals};
      queryStringFq += '&fq=index_id:' + vals.index_id + '&fq=ss_content_type:' + vals.content_type;

      if (new_vals?.solrBoundaryPages) {
        queryStringQ = 'q=*:*';
        queryStringFl = '&fl=score,ss_url,its_nid,id,ss_content_type,ss_uuid,ss_img_uri,ss_img_ext_url,ss_sale_type,tm_X3b_en_city_name,tm_X3b_en_neighbor_name,tm_X3b_en_comm_name,tm_X3b_en_school_name,tm_X3b_en_special_name,ss_boundary_type,its_brokerage,its_eaton,tm_X3b_en_body,tm_X3b_en_summary,ss_image_uri';
      }
      else {
        queryStringQ = vals.txt ? 'q={!type=dismax%20q.op=AND%20qf=tm_X3b_en_body qf=tcngramm_X3b_en_addrsearchngram qf=tcngramm_X3b_en_addr1ngram qf=tm_X3b_en_addr1 qf=tm_X3b_en_addr2 qf=tcngramstringm_X3b_en_cityngram qf=tm_X3b_en_city qf=ss_zip v=%27' + vals.txt + '%27}' : 'q=*:*';

        queryStringFl = '&fl=score,ss_url,its_nid,id,ss_uuid,ss_img_uri,ss_img_ext_url,ss_sale_type,its_price,its_bed,its_bath_all,its_bath_full,its_bath_half,ss_zip,fts_lat,fts_lon,its_brokerage,its_eaton';
        queryStringFl += ',its_sale_status,tm_X3b_en_title,tm_X3b_en_addr1,tm_X3b_en_addr2,tm_X3b_en_city,tm_X3b_en_state';
        if (vals.type == 'Buy') {
          queryStringFl += ',fts_lot_acres,its_sqft,its_garage,sm_prop_type,ds_oh_coming,ds_oh_coming_end'
        }
        else {
          queryStringFl += ',its_sqft,its_market_days,ds_date_avail';
        }
      }
    }

    const MAXPRICE = vals.type == 'Buy' ? 5000000 : 10000;
    const MAXSQFT = vals.type == 'Buy' ? 6000 : 6000;
    let PROP_TYPE = propTypesSaleOpts;
    if (vals?.type == 'Rental') {
      PROP_TYPE = propTypesRentOpts;
    }
    const MAXYEAR = new Date().getFullYear();

    // If a list of fields to return was passed in, override:
    if (new_vals.solr_fields) {
      queryStringFl = `&fl=${Array.isArray(new_vals.solr_fields) ? new_vals.solr_fields.join(',') : new_vals.solr_fields}`;
    }

    // For Boundary Pages specifically: type.
    if (vals.boundary_type) {
      vals.boundary_type = Array.isArray(vals.boundary_type) ? vals.boundary_type : [vals.boundary_type];
      if (vals.boundary_type.length > 0) {
        queryStringFq += '&fq=ss_boundary_type:(' + vals.boundary_type.join('%20OR%20') + ')';
      }
    }

    if (!new_vals?.solrBoundaryPages && vals.type) {
      queryStringFq += '&fq=ss_sale_type:"' + vals.type + '"';
    }

    // DO NOT INCLUDE "sold" listings (which can be published nodes in Drupal)
    // https://stackoverflow.com/questions/16743626/solr-filter-query-including-not-and-or
    if (vals.skip_sold) {
      queryStringFq += '&fq=(*:* AND -its_sale_status:3)';
    }

    if (vals.prop_type) {
      vals.prop_type = Array.isArray(vals.prop_type) ? vals.prop_type : [vals.prop_type];
      if (vals.prop_type && vals.prop_type[0] != 0 && vals.prop_type[0] != 'all') {
        let t = [];
        vals.prop_type.map((p) => {
          var v = isNaN(p) ? p : PROP_TYPE[p];
          if (v == 'Multi-Family') {
            t.push('%22Condo%20-%20Hotel%22');
            t.push('%22Condominium%22');
            t.push('%22Duplex%22');
            t.push('%22Half%20Duplex%22');
            t.push('%22Multi-Family%22');
            t.push('%22Quadruplex%22');
            t.push('%22Triplex%22');
          }
          else if (v == 'Single Family') {
            t.push('%22Farm%22');
            t.push('%22Manufactured%20Home%22');
            t.push('%22Mobile%20Home%22');
            t.push('%22Single%20Family%20Residence%22');
          }
          else {
            t.push('%22' + v + '%22');
          }
        });
        if (t.length > 0) {
          queryStringFq += '&fq=sm_prop_type:(' + t.join('%20OR%20') + ')';
        }
      }
    }
    if (vals.pool) {
      queryStringFq += '&fq=bs_pool:true';
    }
    if (vals.comm_pool) {
      queryStringFq += '&fq=bs_comm_pool:true';
    }
    if (vals.featured) {
      queryStringFq += '&fq=its_eaton:1';
    }
    if (vals.waterfront) {
      queryStringFq += '&fq=bs_waterfront:true';
    }
    if (vals.fireplace) {
      queryStringFq += '&fq=bs_fireplace_yn:true';
    }
    if (vals.new_build) {
      queryStringFq += '&fq=bs_new_construction:true';
    }
    // Look up by Taxonomy term assigned (not its GEO value):
    if (vals.city) {
      vals.city = Array.isArray(vals.city) ? vals.city : [vals.city];
      if (vals.city && vals.city[0] && vals.city[0] != 0 && vals.city[0] != 'all') {
        if (!vals?.solrBoundaryPages) {
          queryStringFq += '&fq=its_city_tid:(' + vals.city.join('%20') + ')';
        }
      }
    }
    // Look up by Taxonomy term assigned (not its GEO value):
    if (vals.neighbor) {
      vals.neighbor = Array.isArray(vals.neighbor) ? vals.neighbor : [vals.neighbor];
      if (vals.neighbor && vals.neighbor[0] != 0 && vals.neighbor[0] != 'all') {
        queryStringFq += '&fq=its_neighbor_tid:(' + vals.neighbor.join('%20') + ')';
      }
    }
    // Look up by Taxonomy term assigned (not its GEO value):
    let schools_cond = '';
    if (vals.schools) {
      vals.schools = Array.isArray(vals.schools) ? vals.schools : [vals.schools];
      if (vals.schools && vals.schools[0] != 0 && vals.schools[0] != 'all') {
        schools_cond = '(' + vals.schools.join('%20OR%20') + ')';
        if (!vals?.solrBoundaryPages) {
          queryStringFq += '&fq=(its_el_school:' + schools_cond + '%20OR%20its_mid_school:' + schools_cond + '%20OR%20its_h_school:' + schools_cond + ')';
        }
      }
    }

    if (vals.available_min || vals.available_max) {
      let range = (vals.available_min && vals.available_min != 0) ? '[' + vals.available_min + '%20TO%20' : '[*%20TO%20';
      range += (vals.available_max && vals.available_max != 0) ? vals.available_max + ']' : '*]';
      queryStringFq += '&fq=ds_date_avail:' + range;
    }
    else if (vals.available) {
      // queryStringFq += '&fq=its_sale_status:(1 OR
      // 2)&fq=ds_date_avail:[*%20TO%20NOW]'; should return all properties that
      // have Sale Status of “On Market”
      queryStringFq += '&fq=its_sale_status:(1)';
    }

    if (vals.market_recent && vals.market_recent.length > 0) {
      const mkt = {
        today: 'NOW/DAY',
        this_week: '[NOW-7DAYS/DAY%20TO%20*]',
        this_month: '[NOW-30DAYS/DAY%20TO%20*]'
      };
      queryStringFq += '&fq=ds_date_on_market:' + mkt[vals.market_recent];
    }

    if (vals.openhouse) {
      queryStringFq += '&fq=bm_oh_active:true&fq={!field%20f=drm_ohrange%20op=Intersects}[' + new Date().toISOString().slice(0, 10) + '%20TO%20*]';
    }
    else if (vals.oh_dates_min && vals.oh_dates_max) {
      queryStringFq += '&fq=bm_oh_active:true&fq={!field%20f=drm_ohrange%20op=Intersects}[' + vals.oh_dates_min + '%20TO%20' + vals.oh_dates_max + ']';
    }
    else if (vals.oh_dates_min || vals.oh_dates_max) {
      queryStringFq += '&fq=bm_oh_active:true';
      if (vals.oh_dates_min) {
        queryStringFq += '&fq={!field%20f=drm_ohrange%20op=Intersects}[' + vals.oh_dates_min + '%20TO%20*]';
      }
      else if (vals.oh_dates_max) {
        queryStringFq += '&fq={!field%20f=drm_ohrange%20op=Intersects}[NOW%20TO%20' + vals.oh_dates_max + ']';
      }
    }

    if ((vals.price_min !== undefined && vals.price_max !== undefined
      && vals.price_min != 0 && vals.price_min == vals.price_max)) {
      queryStringFq += '&fq=its_price:[' + vals.price_min + ' TO *]';
    }
    else if ((vals.price_min !== undefined && vals.price_max !== undefined && vals.price_min != vals.price_max)
      && (vals.price_min != 0 || vals.price_max != MAXPRICE)) {
      min_p = vals.price_min;
      max_p = (vals.price_max == MAXPRICE || (vals.price_min && vals.price_max == 0)) ? '*' : vals.price_max;
      queryStringFq += '&fq=its_price:[' + min_p + ' TO ' + max_p + ']';
    }

    if ((vals.sqft_min !== undefined && vals.sqft_max !== undefined
      && vals.sqft_min != 0 && vals.sqft_min == vals.sqft_max)) {
      queryStringFq += '&fq=its_sqft:[' + vals.sqft_min + ' TO *]';
    }
    else if ((vals.sqft_min !== undefined && vals.sqft_max !== undefined && vals.sqft_min != vals.sqft_max)
      && (vals.sqft_min != 0 || vals.sqft_max != MAXSQFT)) {
      min_p = vals.sqft_min;
      max_p = (vals.sqft_max == MAXSQFT || (vals.sqft_min && vals.sqft_max == 0)) ? '*' : vals.sqft_max;
      queryStringFq += '&fq=its_sqft:[' + min_p + ' TO ' + max_p + ']';
    }

    if ((vals.acres_min !== undefined && vals.acres_max !== undefined
      && vals.sqft_min != 0 && vals.sqft_min == vals.acres_max)) {
      queryStringFq += '&fq=fts_lot_acres:[' + vals.acres_min + ' TO *]';
    }
    else if ((vals.acres_min !== undefined && vals.acres_max !== undefined && vals.acres_min != vals.acres_max)
      && (vals.acres_min != 0 || vals.acres_max != 20)) {
      min_p = vals.acres_min;
      max_p = (vals.acres_max == 20 || (vals.acres_min && vals.acres_max == 0)) ? '*' : vals.acres_max;
      queryStringFq += '&fq=fts_lot_acres:[' + min_p + ' TO ' + max_p + ']';
    }

    if ((vals.year_min !== undefined && vals.year_max !== undefined
      && vals.year_min != 0 && vals.year_min == vals.year_max)) {
      queryStringFq += '&fq=its_year:[' + vals.year_min + ' TO *]';
    }
    else if ((vals.year_min !== undefined && vals.year_max !== undefined && vals.year_min != vals.year_max)
      && (vals.year_min != 0 || vals.year_max != MAXYEAR)) {
      min_p = vals.year_min;
      max_p = (vals.year_max == MAXYEAR || (vals.year_min && vals.year_max == 0)) ? '*' : vals.year_max;
      queryStringFq += '&fq=its_year:[' + min_p + ' TO ' + max_p + ']';
    }

    if (vals.beds && vals.beds != 0 && vals.beds != 'all') {
      queryStringFq += '&fq=its_bed:[' + vals.beds + ' TO *]';
    }
    if (vals.baths && vals.baths != 0 && vals.baths != 'all') {
      queryStringFq += '&fq=its_bath_all:[' + vals.baths + ' TO *]';
    }

    if ((vals.garage_min !== undefined && vals.garage_max !== undefined
      && vals.garage_min != 0 && vals.garage_min == vals.garage_max)) {
      queryStringFq += '&fq=its_garage:[' + vals.garage_min + ' TO *]';
    }
    else if ((vals.garage_min !== undefined && vals.garage_max !== undefined && vals.garage_min != vals.garage_max)
      && (vals.garage_min != 0 || vals.garage_max != 5)) {
      min_p = vals.garage_min;
      max_p = vals.garage_max == 5 ? '*' : vals.garage_max;
      queryStringFq += '&fq=its_garage:[' + min_p + ' TO ' + max_p + ']';
    }

    if (vals.geo_area?.length > 0) {
      queryStringFq += `&fq={!field f=rpts_coords}Intersects(${vals.geo_area})`;
    }

    if (vals.geo_boundary) {
      const boundary_geo_fields = ['rpts_neighbor_geo', 'rpts_comm_geo', 'rpts_special_geo'];
      let f_arr = [];
      boundary_geo_fields.map((g) => {
        f_arr.push(`_query_:%22{!field%20f=${g}}Contains(${vals.geo_boundary})%22`);
      });
      if (vals?.solrBoundaryPages && schools_cond?.length > 0) {
        f_arr.push(`_query_:%22its_schools:${schools_cond}%22`);
      }
      if (vals?.solrBoundaryPages && vals.city?.length > 0 && vals.city[0] && vals.city[0] != 0 && vals.city[0] != 'all') {
        f_arr.push(`_query_:%22its_city_tid:(${vals.city.join('%20')})%22`);
      }
      queryStringFq += '&fq=(' + f_arr.join('%20OR%20') + ')';
    }

    // Sort order:
    if (!Array.isArray(vals.sort) && vals.sort.field) {
      vals.sort = [vals.sort];
    }
    if (Array.isArray(vals.sort) && vals.sort?.length) {
      var sort_arr = [];
      if (vals.type == 'Buy') {
      }
      else if (vals.type == 'Rental') {
        if (vals.sort[0]['field'] == 'its_eaton') {
          sort_arr.push('ds_date_avail%20asc');
        }
      }

      vals.sort.map((s) => {
        if (!sort_arr.includes('score%20DESC')) {
          sort_arr.push(s.field + '%20' + s.dir);
        }
        if (vals.sort.length == 1 && !new_vals.solrBoundaryPages /*&& vals.sort[0]['field'] == 'its_eaton'*/) {
          if (!sort_arr.includes('score%20DESC')) {
            sort_arr.push('score%20desc');
          }
          if (vals.sort[0]['field'] == 'its_eaton') {
            sort_arr.push('its_price%20desc');
          }
        }
      });

      if (new_vals?.rent_sale && new_vals?.solrBoundaryPages) {
        if (new_vals?.rent_sale == 'Buy') {
          sort_arr.push('ss_sale_type%20asc');
        }
        if (new_vals?.rent_sale == 'Rental') {
          sort_arr.push('ss_sale_type%20desc');
        }
      }

      if (sort_arr.length > 0) {
        sortString += sort_arr.join('%2C%20');
      }
    }
    else {
      sortString += 'score%20desc';
    }

    res = queryStringQ + queryStringFq + queryStringFl + queryStringFacet + queryStringQF + sortString;

    if (vals.start && !res.includes('&start=')) {
      res += `&start=${vals.start}`;
    }
    if (vals.ppage && !res.includes('&ppage=')) {
      res += `&ppage=${vals.ppage}`;
    }
    // console.log('solr q ======    ' + res);
    return res;
  }

  /**
   * Parse URL query into Solr query string, e.g. from /buy/homes-for-sale?aah=20&afh=6000&gh=5&bed=2&sort=DESC_feat
   * parsed in from url_query as Object.
   *
   * Object keys are SHORT-ENCODED URL variable names, e.g. "afh, gh, bed".
   */
  function parseUrlQuery(url_query) {
    let new_query = {};

    Object.keys(map_vals).map(k => {
      if (url_query[map_vals[k]]?.length > 0 || url_query[map_vals[k]]?.toString()) {
        if (['txt', 'prop_type', 'sort'].includes(k)) {
          let v = decodeURI(url_query[map_vals[k]]);
          if (k == 'txt') {
            new_query = {...new_query, [k]: url_query[map_vals[k]]}
          }
          else if (k == 'prop_type' && v.length > 0) {
            if (v.includes(',')) {
              let v_arr = v.split(','), v_new = [];
              v_arr.map((av) => {
                if (!isNaN(av)) {
                  v_new.push(parseInt(av));
                }
              });
              new_query = {...new_query, [k]: v_new};
            }
            else {
              new_query = {...new_query, [k]: [v]};
            }
          }
          else if (k == 'sort' && v.length > 0 && v.includes('_')) {
            let v_arr = v.split('_', 2);
            Object.keys(sort_vals).map(s => {
              if (sort_vals[s] == v_arr[1]) {
                new_query = {
                  ...new_query,
                  sort: [{field: s, dir: v_arr[0]?.toUpperCase()}]
                };
              }
            });
          }
        }
        else if (!isNaN(url_query[map_vals[k]])) {
          new_query = {...new_query, [k]: parseInt(url_query[map_vals[k]])};
        }
      }
    });
    return new_query;
  }

  return {assembleQuery, parseUrlQuery, assembleSearchShareQuery};
}
