// https://github.com/vercel/next.js/tree/canary/examples/with-static-export
import React, {useEffect, useReducer, useState} from 'react';
import {useRouter} from 'next/router';
import { format } from 'url';
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import dynamic from "next/dynamic";
import useSWR from 'swr';
import ReactPaginate from 'react-paginate';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListingSearchContext
  from '../../components/context/ListingSearchContext';
import NoResultsMessage from '../../components/01_atoms/NoResultsMessage';
import * as listingApi from '../../api/listing';
import CustomMuiButton from '../../components/01_atoms/CustomMuiButton';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../../components/01_atoms/Spinner';
import Error from '../../components/01_atoms/ErrorMessage';
// import InfiniteScrollPager from "../../components/01_atoms/Pagination/InfiniteScrollPager";
import GlobalFooter
  from '../../components/03_organisms/GlobalFooter/Footer';
import {getSolrClient} from '../../utils/solr';
import {fetchAPIRequestData} from "../../api/global";
// import ContentList from "../../components/02_molecules/ContentList";
const ListingsListComponent = dynamic(() => import('../../components/02_molecules/ListingsList'));
const DynamicSearchForm = dynamic(() => import('../../components/01_atoms/ListingSearchForm'), {ssr: false});
const MapWithAMarkerClusterer = dynamic(() => import('../../components/01_atoms/Maps/MapClusters'), {ssr: false});

const useStyles = makeStyles((theme) => ({
    root: {
      '& .smalltext': {
        fontSize: '13px',
        '& p, & div, & span': {
          fontSize: '13px',
        },
        '& ul': {
          columns: 2,
          '-webkit-columns': 2,
          '-moz-columns': 2,
        },
        '& ul.cols-3': {
          columns: 3,
          '-webkit-columns': 3,
          '-moz-columns': 3,
        },
        '& a': {
          color: '#0071B3',
        },
        '& h3, & h4': {
          margin: '1.5rem 0 0.5rem',
        }
      },
      '& .outer-map-wrapper': {
        width: 'calc(100vw - 650px)',
        [theme.breakpoints.down("md")]: {
          width: 'calc(100vw - 325px)',

        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
        }
      },
      '& .mapListingWrapper': {
        width: '650px',
        [theme.breakpoints.down("md")]: {
          width: '325px',
          '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
            maxWidth: '100%',
            flexBasis: '100%',
          },

        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
        },
      },
    },
  }),
);

/**
 * Amount of listings to display per page.
 */
const LISTINGS_PER_PAGE = 24;
const LISTINGS_SOLR_LIMIT = 500;
export const resetDefaultValues = {
  txt: '',
  type: 'Buy',
  prop_type: [0],
  price_min: 0,
  price_max: 5000000,
  city: ['all'],
  neighbor: ['all'],
  beds: 0,
  baths: 0,
  pool: false,
  waterfront: false,
  featured: false,
  available: false,
  openhouse: false,
  myfavorite: false,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  page: 0,
};

let defaultValues = {
  // txt: '',
  type: 'Buy',
  price_min: 0,
  price_max: 5000000,
  acres_min: 0,
  acres_max: 20,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  garage_min: 0,
  garage_max: 5,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  // page: 0,
};

export async function getServerSideProps(context) {
  let initialProps = {
    listings: [],
    lookup: false,
    initial: true,
    query: context.query,
    statusCode: 200,
  };
  let search_city, search_neighbor;

  try {
    // Get list of listings from Drupal backend.
    [search_city, search_neighbor] = await Promise.all([
      fetchAPIRequestData(`/api-admin/rest/view/city-terms`, {}, {type: 'tld'}),
      fetchAPIRequestData(`/api-admin/rest/view/neighbor-terms`, {}, {type: 'tld'})
    ]);
  }
  catch (e) {
    console.warn('Error', e);

    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side rendering we want the server to throw the
    // correct error code.
    // if (res) res.statusCode = 500;
  }

  // If certain page does not contain listings, we throw "Page not found"
  // header. It's needed for the right SEO.
  // if (!initialProps.listings.length && currentPage > 0) {
  // Pass status code as internal properly. It is being checked inside of
  // render() method of _app.js.
  // initialProps.statusCode = 404;

  // In case of Server Side rendering we want the server to throw the
  // correct error code.
  // if (res) res.statusCode = 404;
  // }

  return {
    props: {
      ...initialProps,
      cityFilterOpts: (search_city?.page_data || []),
      neighborFilterOpts: (search_neighbor?.page_data || [])
    }, // will be passed to the page component as props
  }
}

function HomesForSalePage(props) {
  let swr_req = {}, solr_q = '', newSettOverride = {};
  const router = useRouter();
  let pager_start = !isNaN(props.query?.start) ? (parseInt(props.query?.start)/LISTINGS_SOLR_LIMIT) : 0;

  let query_obj = {
    solr_fields: 'score,its_nid,id,ss_url,ss_uuid,ss_img_uri,ss_img_ext_url,ss_sale_type,its_sale_status,its_price,its_bed,its_bath_all,ss_zip,fts_lat,fts_lon'
      + ',its_brokerage,its_eaton,its_garage,sm_prop_type,ds_oh_coming,ds_oh_coming_end,fts_lot_acres,its_sqft,tm_X3b_en_title,tm_X3b_en_addr1,tm_X3b_en_addr2,tm_X3b_en_city,tm_X3b_en_state',
    type: 'Buy',
    sort: [{dir: 'DESC', field: 'its_eaton'}],
  };

  const solrClient = getSolrClient();
  if (props.query) {
    // Parse search params from URL query:
    newSettOverride = solrClient.parseUrlQuery(props.query);
    query_obj = {...query_obj, ...newSettOverride};

    // Merge vals to pass to the search form for rendering:
    newSettOverride = {...defaultValues, ...newSettOverride};
  }

  solr_q = solrClient.assembleQuery(query_obj);
  const solrRequestDefault = {
    query: solr_q,
    page_data: [],
    map_bbox: '',
    map_zoom: 10,
    map_center: {lat: 27.964, lng: -82.400},
    curr_page: pager_start || 0,
    curr_offset: 0,
    is_submitting: false,
    initial: true,
    ttlResults: false,
    ttlPages: false,
  };

  const [values, setValues] = useState(solrRequestDefault);

  function ListingSearchContextReducer(state, form_item) {
    const combined = {/*...values,*/ ...state, ...form_item};
    return combined;
  }

  const [solrReq, setSolrReq] = useReducer(ListingSearchContextReducer, values);

  const solr_query = solrReq.map_bbox && solrReq.map_bbox != '' ? solrReq.query + solrReq.map_bbox : solrReq.query;

  swr_req = useSWR((solrReq.initial || solrReq.is_submitting) ? ['/solr', LISTINGS_SOLR_LIMIT, solrReq.curr_page * LISTINGS_PER_PAGE, solr_query] : null, ([url, pageLimit, pageOffset, q]) => listingApi.listingsSolrSearch(url, pageLimit, pageOffset, q), {
    // initialData: initData,
    // revalidateOnMount: true,
  });
  const {data, error} = swr_req;

  // --------------   @see https://swr.vercel.app/examples/infinite-loading
  let listings = solrReq && solrReq.page_data ? solrReq.page_data : []; // FOR INFINITE LOADING
  // const isLoadingInitialData = !data && !error;
  const isSubmitted = data || error;
  // const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof
  // data[size - 1] === "undefined");
  const isEmpty = solrReq?.ttlResults === 0;
  // const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length <
  // PAGE_SIZE); const isRefreshing = isValidating && data && data.length ===
  // size;

  if (isSubmitted) {
    let fresh_data = {listings: []};
    fresh_data = {...fresh_data, initial: false};

    // For the first page load noPagerSet is UNDEFINED, change it now to trigger
    // a new page.
    if (typeof solrReq.noPagerSet === 'undefined') {
      fresh_data = {...fresh_data, noPagerSet: true};
      if (router.query?.start) {
        fresh_data['pagerSetStartPage'] = router.query?.start;
      }
    }
    if (!data?.page_data) {
      fresh_data = {
        ...fresh_data,
        listings: [],
        ttlResults: data?.ttlResults,
        ttlPages: 0
      };
      listings = [];
    }
    if (data?.page_data) {
      fresh_data = {
        ...fresh_data,
        page_data: data.page_data,
        ttlResults: data?.ttlResults,
        ttlPages: data?.ttlPages
      };
      listings = data.page_data;
    }
    if (solrReq.is_submitting) {
      fresh_data = {...fresh_data, is_submitting: false};
    }
    setSolrReq({...solrReq, ...fresh_data});
  }

  useEffect(() => {
    document.body.classList.remove('not-search-body');
  }, []);

  const classes = useStyles();

  const noResultsMessage = (
    <Box p={4}>
      <Typography variant="h4" component="h2"><strong>No results found</strong></Typography>
      <Typography>Please reset the filters to continue searching.</Typography>
      <CustomMuiButton href="/buy/homes-for-sale" color="primary"
                       size="large" label={"Reset"}
                       stronglabel={"Search"}></CustomMuiButton>
    </Box>
  );

  return (
    <>
      <div className={classes.root}>
        <div className="alignfull listingWrapper">
          <ListingSearchContext.Provider value={{solrReq, setSolrReq}}>
            {(error) &&
            <Error/>
            }

            <Grid container spacing={3}>
              <Grid item xs>
                <DynamicSearchForm type={'Buy'} cities={props?.cityFilterOpts}
                                   neighbors={props?.neighborFilterOpts}
                                   settOverride={newSettOverride}/>
              </Grid>
            </Grid>

            {(solrReq.is_submitting || solrReq.initial) &&
            <Spinner/>
            }
            <h1 className="sr-only">Eaton Realty Homes for Sale</h1>
            <Grid container spacing={0}>
              <Grid className={`outer-map-wrapper`} item>
                <Hidden smDown>
                <div className={`map-wrapper`}>

                  <div
                    className={`info-msg`}>Showing {solrReq?.page_data?.length} out of {solrReq.ttlResults} properties
                    in this area.<br/>Zoom or move the map to view more.
                  </div>
                  <MapWithAMarkerClusterer markers={listings}/>
                  <div className="legend-color">
                    <div className="item multiple">Multiple Properties</div>
                    <div className="item p-normal">Property for Sale</div>
                    <div className="item p-featured">Featured Property for
                      Sale
                    </div>
                  </div>
                </div>
                </Hidden>
              </Grid>
              <Grid item className="mapListingWrapper" id={`mapListingWrapper-sale`}>
                <Box>
                  {solrReq?.page_data?.length > 0 && !isEmpty &&
                  <>
                    <Box px={1}>
                      {/*<InfiniteScrollPager scrollData={listings} gridCols={6}*/}
                      {/*                     nodeType={`listing`} layout={`card`}*/}
                      {/*                     perPage={12}*/}
                      {/*>*/}
                      {/*</InfiniteScrollPager>*/}
                      <ListingsListComponent key={`homes-for-sale-wrap`} id={`homes-for-sale-wrap`} scrollTo={`mapListingWrapper-sale`} listings={listings}
                                             colMd={6} showPager={true} perPage={LISTINGS_PER_PAGE} pagerCurr={solrReq.curr_page} query_obj={query_obj}/>

                      <br/>
                      <div className={`smalltext descr`}>
                        <h3>Tampa Real Estate & Homes For Sale</h3>
                        Eaton Realty’s home listing directory contains many
                        homes available for sale in Tampa. You can use the
                        directory to find homes you might be interested in
                        touring. Because a home is one of the largest
                        purchases you’ll make in your lifetime, it’s important
                        to work with an experienced Tampa real estate agent
                        who knows the market well enough to help you find the
                        right home and make sure you’re paying the best
                        possible price.
                        <br/><br/>
                        If you see a listing you like on our site, our agents
                        can schedule a showing for you. We can also help you
                        set up a custom search so you don’t have to manually
                        search on your own. You can contact us to schedule a
                        showing or setup a search by calling <a href={`tel:8136728022`}>813-672-8022</a> or
                        filling out an <a href={`/contact-us`}>online contact form</a>.
                        <br/>

                        <h3>Neighborhoods To Consider In Tampa</h3>

                        Tampa has around 84 neighborhoods that are officially
                        named. You can find the list of neighborhoods in Tampa
                        below. Our realtors can help you set up a search if
                        you’re looking for a home in a specific neighborhood.
                        Searches on a smaller scale like a neighborhood tend
                        to have fewer results and more competition, so you’ll
                        want a realtor in your corner sending you listings as
                        they enter the market to make sure you’re able to act
                        quickly.
                        <br/>
                        <ul>
                          <li>Armenia Gardens Estates</li>
                          <li>Audubon Park</li>
                          <li>Ballast Point</li>
                          <li>Bayshore Beautiful</li>
                          <li>Bayshore Gardens</li>
                          <li>Bayside West</li>
                          <li>Beach Park</li>
                          <li>Beach Park Isles</li>
                          <li>Beasley</li>
                          <li>Bel Mar Shores</li>
                          <li>Bon Air</li>
                          <li>Carver City-Lincoln Gardens</li>
                          <li>Channel District</li>
                          <li>College Hill</li>
                          <li>Culbreath Bayou</li>
                          <li>Culbreath Heights</li>
                          <li>Culbreath Isles</li>
                          <li>Davis Islands</li>
                          <li>Dixie Farms</li>
                          <li>Downtown Tampa</li>
                          <li>Drew Park</li>
                          <li>Eastern Heights</li>
                          <li>East Tampa</li>
                          <li>East Ybor</li>
                          <li>Fair Oaks - Manhattan Manor</li>
                          <li>Fern Cliff</li>
                          <li>Florence Villa</li>
                          <li>Forest Hills</li>
                          <li>Gandy-Sun Bay South</li>
                          <li>Golf View</li>
                          <li>Grant Park</li>
                          <li>Gray Gables</li>
                          <li>Hampton Terrace Historic District</li>
                          <li>Harvey Heights</li>
                          <li>Highland Pines</li>
                          <li>Historic Hyde Park North</li>
                          <li>Hyde Park</li>
                          <li>Harbour Island</li>
                          <li>Hunter's Green</li>
                          <li>Interbay</li>
                          <li>Live Oaks Square</li>
                          <li>Lowry Park</li>
                          <li>Lowry Park North</li>
                          <li>Maryland Manor</li>
                          <li>New Suburb Beautiful</li>
                          <li>North Bon Air</li>
                          <li>Northeast Community</li>
                          <li>North Hyde Park</li>
                          <li>North Tampa</li>
                          <li>Northview Hills</li>
                          <li>Oakford Park</li>
                          <li>Oak Park</li>
                          <li>Old Seminole Heights</li>
                          <li>Palma Ceia</li>
                          <li>Palma Ceia Pines</li>
                          <li>Palma Ceia West</li>
                          <li>Palmetto Beach</li>
                          <li>Parkland Estates</li>
                          <li>Port Tampa</li>
                          <li>Rattlesnake</li>
                          <li>Rembrandt Gardens</li>
                          <li>Ridgewood Park</li>
                          <li>River Arts District</li>
                          <li>Riverbend</li>
                          <li>Rivercrest</li>
                          <li>River Grove</li>
                          <li>Riverside Heights</li>
                          <li>Robles Park</li>
                          <li>Soho District</li>
                          <li>South Seminole Heights</li>
                          <li>Southeast Seminole Heights</li>
                          <li>Southern Pines</li>
                          <li>South Nebraska</li>
                          <li>South Westshore</li>
                          <li>Sulphur Springs</li>
                          <li>Sunset Park</li>
                          <li>Swann Estates</li>
                          <li>Tampa Heights</li>
                          <li>Tampa Overlook</li>
                          <li>Tampa Palms</li>
                          <li>Temple Crest</li>
                          <li>Terrace Park</li>
                          <li>University Square</li>
                          <li>V.M. Ybor</li>
                          <li>Virginia Park</li>
                          <li>Wellswood</li>
                          <li>West Hyde Park</li>
                          <li>West Meadows</li>
                          <li>West Tampa</li>
                          <li>Water Street</li>
                          <li>West Riverfront</li>
                          <li>Westshore Palms</li>
                          <li>Woodland Terrace</li>
                          <li>Ybor City</li>
                        </ul>
                        <h3>Zip Codes In Tampa</h3>

                        If you know the Tampa area well, there may be certain
                        zip codes you’re interested in and others you’re not
                        interested in. Our team can open a search for you with
                        specific zip codes filtered if you know which zip
                        codes you’re interested in. You can find the list of
                        zip codes in Tampa below.
                        <ul className={`cols-3`}>
                          <li>33602</li>
                          <li>33603</li>
                          <li>33604</li>
                          <li>33605</li>
                          <li>33606</li>
                          <li>33607</li>
                          <li>33609</li>
                          <li>33610</li>
                          <li>33611</li>
                          <li>33612</li>
                          <li>33613</li>
                          <li>33614</li>
                          <li>33615</li>
                          <li>33616</li>
                          <li>33617</li>
                          <li>33618</li>
                          <li>33619</li>
                          <li>33620</li>
                          <li>33621</li>
                          <li>33624</li>
                          <li>33625</li>
                          <li>33626</li>
                          <li>33629</li>
                          <li>33633</li>
                          <li>33634</li>
                          <li>33635</li>
                          <li>33637</li>
                          <li>33647</li>
                        </ul>


                        <h3>Schools In Tampa</h3>
                        Schools are an important consideration for families
                        moving to Tampa. After all, you want to give your
                        children the best possible education. Fortunately,
                        Hillsborough County Schools are excellent. The school
                        district ranks above average according to Niche. The
                        district ranks as the 24th best overall in Florida and
                        the third best for athletes. Approximately 80% of
                        teachers in the district have more than three years of
                        experience. The student-to-teacher ratio is also below
                        the state average at 16:1, so students get more
                        personal attention and a better learning experience
                        overall.

                        <h3>How To Buy A Home In Tampa</h3>
                        Buying a home in Tampa may seem overwhelming when you
                        first start your search, but our realtors have a proven
                        workflow to make the process stress-free.

                        <h4>Pre-Approval</h4>
                        First, it's essential to determine your budget and get
                        pre-approved for a mortgage. Knowing your buying power
                        will help you understand your financial capabilities and
                        narrow your search to homes within your price range. If
                        you don’t already have a strong relationship with a
                        banker or broker, we can connect you with our
                        recommended broker, The Rice Financial Group.

                        <h4>Search</h4>
                        Next, you’ll work with a realtor on our team to outline
                        your needs and wants in a new home. You’ll discuss your
                        location priorities, desired amenities and features, and
                        more. Your Eaton realtor will then create a search for
                        homes based on your needs and wants. You’ll be directly
                        emailed new homes that meet your search criteria, and we
                        can adjust the frequency of these emails to fit your
                        preferences.

                        <h4>Showings</h4>
                        Once you identify potential properties you’re interested
                        in, your Eaton Realtor will schedule showings for you to
                        walk through the home. We can do virtual showings if you
                        live out of the area, but we recommend in-person
                        showings because you can explore the home in-depth. If
                        there’s a home that you like, our team can help you
                        write an offer for the home. We’ll be able to advise on
                        what we believe will be a competitive offer.

                        <h4>Offer & Inspection</h4>
                        The homeowners may decide to counter your offer, in
                        which case negotiations will begin. You’ll work closely
                        with your Eaton agent to navigate the negotiation
                        process. If your agreement is accepted at any point,
                        you’ll want to get an inspection scheduled with a
                        professional home inspector to make sure the property is
                        a sound investment with no major potential issues.

                        <h4>Appraisal & Closing</h4>

                        After the home inspection, the bank will need to send
                        out a representative for an appraisal to ensure the
                        home’s value justifies the sale price. If the home
                        passes appraisal, you can schedule a closing date to
                        close on the home and transfer ownership.
                        <br/><br/>
                        Remember, buying a home in Tampa is a significant
                        investment, so taking the time to research, consult
                        with our real estate professionals, and make
                        well-informed decisions will contribute to a
                        successful and satisfying home-buying experience.


                        <h3>Tampa Home Prices</h3>
                        Home prices in Tampa will vary significantly
                        depending on the neighborhood, the condition of the
                        home, and the amenities and features the home offers.
                        The median home price of a house in Tampa is $436,033.
                        You can find homes under this price, but there are
                        just as many homes over this price as well. The median
                        price of homes in neighborhoods like Davis Islands or
                        Hyde Park is over $900,000.
                        <br/><br/>
                        Our realtors can work with your budget to find the
                        best possible home at the best possible price. If
                        you’re willing to expand your search outside of Tampa,
                        we can also suggest neighborhoods and cities in the
                        surrounding area that may allow you to stretch your
                        dollar further.


                        <h3>Popular House Features & Styles In Tampa</h3>
                        Tampa has a vibrant and diverse culture, and the homes
                        in the area reflect that. There is a great amount of
                        variety in the size, features, and architectural style
                        of Tampa homes. Below are a few popular features and
                        styles homebuyers commonly search for in Tampa.

                        <h4>Villas In Tampa</h4>
                        Villa-style homes are paired homes that share a common
                        wall. Single-level, they’re often found in gated
                        communities within an HOA that takes care of landscape
                        maintenance, roof replacement, and exterior painting.
                        You can see available villas for sale in Tampa here.

                        <h4>Homes With A Pool In Tampa</h4>
                        Summers in Tampa can be hot, with temperatures averaging
                        a high of 90°F in July. It’s great to have a pool on
                        your property to cool down when temperatures get too
                        hot. A pool can also be a money-making asset with pool
                        rental apps like Swimply. Our realtors can help you find
                        the perfect house in Tampa with a pool. You can view
                        current houses for sale in Tampa with a pool here.

                        <h4>Waterfront Homes In Tampa</h4>
                        One of the most beautiful features of Tampa is the Tampa
                        Bay. Your mornings would look and feel very different if
                        they started with a view of the bay or any of the inland
                        bodies of water in the area. If a waterfront home is
                        what you seek, we can help you find it. You can view
                        waterfront homes for sale in Tampa here.

                        <h4>Homes With A Loft Tampa</h4>
                        Lofts provide a great space for storage, and they can
                        also make for cozy living spaces if they are finished. A
                        home with a loft can give you more liveable space so
                        that you’re able to maximize the amount of livable home
                        you’re paying for. You can view homes for sale with a
                        loft in Tampa here.

                        <h3>Houses For Rent In Tampa</h3>
                        Not finding the right house to buy? Many people decide
                        to rent in Tampa while they save and search for the
                        right home. In addition to helping homeowners buy and
                        sell properties, Eaton manages rental homes. You can
                        search our Tampa rental listings to find homes available
                        for rent in Tampa.

                        <h3>Neighboring Cities To Consider Outside Of Tampa</h3>
                        Tampa is a great city to live in, but the number of
                        available homes that meet your search criteria will be
                        smaller if your search is confined exclusively to the
                        city borders. You may want to consider expanding your
                        search to the many great neighboring cities in
                        Hillsborough County. Cities near Tampa include:
                        <ul>
                          <li>Apollo Beach</li>
                          <li>Brandon</li>
                          <li>Dover</li>
                          <li>Gibsonton</li>
                          <li>Lithia</li>
                          <li>Plant City</li>
                          <li>Riverview</li>
                          <li>Ruskin</li>
                          <li>Seffner</li>
                          <li>Temple Terrace</li>
                          <li>Thonotosassa</li>
                          <li>Valrico</li>
                          <li>Wimauma</li>
                        </ul>
                        <h3>Tampa House Listing FAQs</h3>

                        <h4>When Is The Best Time To House Shop In Tampa?</h4>
                        The answer to this question depends on many different
                        factors. There’s rarely a bad time to shop for a home
                        in Tampa, and our reply for the best time to buy a
                        house is usually now. We don’t know what the future
                        holds, but historical trends and prices indicate a
                        continual increase in home prices as time goes on.
                        It’s better to purchase a home now than wait several
                        months and buy at higher prices after homes have
                        appreciated further.
                        <br/><br/>
                        That being said, prices fluctuate throughout the year,
                        and you may be able to strategically search at certain
                        times of the year to get a better deal on a home if
                        you don’t need a new one immediately.
                        <br/><br/>
                        Generally, the spring months, particularly March to
                        May, tend to be a favorable time to purchase a house
                        in Tampa. The weather is pleasant, and many sellers
                        are motivated to list their properties during this
                        period. The increased supply of homes on the market
                        can provide buyers with more options and potentially
                        negotiate better deals. Additionally, the demand for
                        housing tends to rise in the spring as families aim to
                        settle before the start of the new school year.
                        <br/><br/>
                        Another opportune time to buy a house in Tampa is
                        during the off-peak season, which typically falls
                        between November and February. The market may
                        experience reduced competition as fewer buyers search
                        for homes during this time. Sellers who have listed
                        their properties during this period might be more
                        willing to negotiate on prices, offering potential
                        buyers a chance to secure favorable deals. However,
                        it's important to note that the inventory of available
                        homes may be slightly limited compared to the spring
                        season.
                        <br/><br/>
                        Ultimately, while these timeframes may offer
                        advantageous conditions for purchasing a house in
                        Tampa, it's crucial to consider personal circumstances
                        and consult with a Tampa real estate agent who can
                        provide valuable insights into the local market
                        dynamics and individual needs.


                        <h4>How Frequently Are These Listings Updated?</h4>
                        Our listing map pulls directly from the multiple
                        listing service (MLS). These listings are updated
                        multiple times throughout the day, so multiple updates
                        occur daily. New homes listed could appear on our site
                        as quickly as 15 minutes after being posted to the
                        MLS. Certain updates to home listings (such as a
                        listing going pending) could take several hours to
                        update.
                        <br/><br/>
                        Property information is provided exclusively for
                        consumers&apos; personal, non-commercial use and may
                        not be used for any purpose other than to identify prospective
                        properties consumers may be interested in purchasing. Data is deemed
                        reliable but is not guaranteed.
                        <br/><br/>Data courtesy Stellar MLS as
                        of {new Date().toUTCString()}
                      </div>
                      <br/>
                    </Box>
                  </>
                  }
                  {(!solrReq.ttlResults || !solrReq.ttlPages) &&
                  <NoResultsMessage message={noResultsMessage}/>
                  }
                  <Box mt={3}>
                    <GlobalFooter/>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </ListingSearchContext.Provider>
        </div>
      </div>
    </>
  );
}

export default HomesForSalePage;
