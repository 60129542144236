// https://github.com/vercel/next.js/tree/canary/examples/with-static-export
import React, {useEffect, useReducer, useState} from 'react';
import {useRouter} from 'next/router';
import {format} from 'url';
import dynamic from "next/dynamic";
import useSWR from 'swr';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListingSearchContext
  from '../../components/context/ListingSearchContext';
import NoResultsMessage from '../../components/01_atoms/NoResultsMessage';
import * as listingApi from '../../api/listing';
import CustomMuiButton from '../../components/01_atoms/CustomMuiButton';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../../components/01_atoms/Spinner';
import Error from '../../components/01_atoms/ErrorMessage';
// import InfiniteScrollPager from "../../components/01_atoms/Pagination/InfiniteScrollPager";
import GlobalFooter
  from '../../components/03_organisms/GlobalFooter/Footer';
import {getSolrClient} from '../../utils/solr';
import {fetchAPIRequestData} from "../../api/global";

const ListingsListComponent = dynamic(() => import('../../components/02_molecules/ListingsList'));
const DynamicSearchForm = dynamic(() => import('../../components/01_atoms/ListingSearchForm'), {ssr: false});
const MapWithAMarkerClusterer = dynamic(() => import('../../components/01_atoms/Maps/MapClusters'), {ssr: false});

const useStyles = makeStyles((theme) => ({
  root: {
    '& .smalltext': {
      fontSize: '13px',
      '& p, & div, & span': {
        fontSize: '13px',
      },
      '& ul': {
        columns: 2,
        '-webkit-columns': 2,
        '-moz-columns': 2,
      },
      '& ul.cols-3': {
        columns: 3,
        '-webkit-columns': 3,
        '-moz-columns': 3,
      },
      '& a': {
        color: '#0071b3',
      },
      '& h3, & h4': {
        margin: '1.5rem 0 0.5rem',
      }
    },
    '& .outer-map-wrapper': {
      width: 'calc(100vw - 650px)',
      [theme.breakpoints.down("md")]: {
        width: 'calc(100vw - 325px)',

      },
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      }
    },
    '& .mapListingWrapper': {
      width: '650px',
      [theme.breakpoints.down("md")]: {
        width: '325px',
        '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
          maxWidth: '100%',
          flexBasis: '100%',
        },

      },
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      },
    },
  },
}));

/**
 * Amount of listings to display per page.
 */
const LISTINGS_PER_PAGE = 24;
const LISTINGS_SOLR_LIMIT = 500;
export const resetDefaultValues = {
  txt: '',
  type: 'Rental',
  prop_type: [0],
  price_min: 0,
  price_max: 10000,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  beds: 0,
  baths: 0,
  pool: false,
  waterfront: false,
  featured: false,
  available: false,
  openhouse: false,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  page: 0,
};

let defaultValues = {
  // txt: '',
  type: 'Rental',
  // prop_type: [0],
  price_min: 0,
  price_max: 10000,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  // beds: 0,
  // baths: 0,
  // pool: false,
  // waterfront: false,
  // featured: false,
  // available: false,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  // page: 0,
};

export async function getServerSideProps(context) {
  let initialProps = {
    listings: [],
    lookup: false,
    initial: true,
    query: context.query,
    statusCode: 200,
  };
  let search_city, search_neighbor;

  try {
    // Get list of listings from Drupal backend.
    [search_city, search_neighbor] = await Promise.all([
      fetchAPIRequestData(`/api-admin/rest/view/city-terms`, {}, {type: 'tld'}),
      fetchAPIRequestData(`/api-admin/rest/view/neighbor-terms`, {}, {type: 'tld'})
    ]);
  }
  catch (e) {
    console.warn('Homes for Rent Error', e);

    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side rendering we want the server to throw the
    // correct error code.
    // if (res) res.statusCode = 500;
  }

  // If certain page does not contain listings, we throw "Page not found"
  // header. It's needed for the right SEO.
  // if (!initialProps.listings.length && currentPage > 0) {
  // Pass status code as internal properly. It is being checked inside of
  // render() method of _app.js.
  // initialProps.statusCode = 404;

  // In case of Server Side rendering we want the server to throw the
  // correct error code.
  // if (res) res.statusCode = 404;
  // }

  return {
    props: {
      ...initialProps,
      cityFilterOpts: (search_city?.page_data || []),
      neighborFilterOpts: (search_neighbor?.page_data || [])
    }, // will be passed to the page component as props
  }
}

function HomesForRentPage(props) {
  let swr_req = {}, solr_q = '', newSettOverride = {};
  const router = useRouter();
  let pager_start = !isNaN(props.query?.start) ? (parseInt(props.query?.start)/LISTINGS_SOLR_LIMIT) : 0;

  let query_obj = {
    solr_fields: 'score,its_nid,id,ss_url,ss_uuid,ss_img_uri,ss_img_ext_url,ss_sale_type,its_price,its_bed,its_bath_all,tm_X3b_en_title,tm_X3b_en_addr1,tm_X3b_en_addr2,tm_X3b_en_city,tm_X3b_en_state,ss_zip,fts_lat,fts_lon'
        + ',its_brokerage,its_eaton,sm_prop_type,its_sale_status,its_market_days,ds_date_avail,fts_lot_acres,its_sqft',
    type: 'Rental',
    sort: [{dir: 'DESC', field: 'its_eaton'}],
  };

  const solrClient = getSolrClient();
  if (props.query) {
    // Parse search params from URL query:
    newSettOverride = solrClient.parseUrlQuery(props.query);
    query_obj = {...query_obj, ...newSettOverride};

    // Merge vals to pass to the search form for rendering:
    newSettOverride = {...defaultValues, ...newSettOverride};
  }

  solr_q = solrClient.assembleQuery(query_obj);
  const solrRequestDefault = {
    query: solr_q,
    page_data: [],
    map_bbox: '',
    map_zoom: 11,
    map_center: {lat: 27.904, lng: -82.450},
    curr_page: pager_start || 0,
    curr_offset: 0,
    is_submitting: false,
    initial: true,
    ttlResults: false,
    ttlPages: false,
  };

  const [values, setValues] = useState(solrRequestDefault);

  function ListingSearchContextReducer(state, form_item) {
    const combined = {/*...values,*/ ...state, ...form_item};
    return combined;
  }

  const [solrReq, setSolrReq] = useReducer(ListingSearchContextReducer, values);

  const solr_query = solrReq.map_bbox && solrReq.map_bbox != '' ? solrReq.query + solrReq.map_bbox : solrReq.query;

  swr_req = useSWR((solrReq.initial || solrReq.is_submitting) ? ['/solr', LISTINGS_SOLR_LIMIT, solrReq.curr_page * LISTINGS_PER_PAGE, solr_query] : null, ([url, pageLimit, pageOffset, q]) => listingApi.listingsSolrSearch(url, pageLimit, pageOffset, q), {
    // initialData: initData,
    // revalidateOnMount: true,
  });
  const {data, error} = swr_req;

  // --------------   @see https://swr.vercel.app/examples/infinite-loading
  let listings = solrReq && solrReq.page_data ? solrReq.page_data : []; // FOR INFINITE LOADING
  // const isLoadingInitialData = !data && !error;
  const isSubmitted = data || error;
  // const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof
  // data[size - 1] === "undefined");
  const isEmpty = solrReq?.ttlResults === 0;
  // const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length <
  // PAGE_SIZE); const isRefreshing = isValidating && data && data.length ===
  // size;

  if (isSubmitted) {
    let fresh_data = {listings: []};
    fresh_data = {...fresh_data, initial: false};

    // For the first page load noPagerSet is UNDEFINED, change it now to trigger
    // a new page.
    if (typeof solrReq.noPagerSet === 'undefined') {
      fresh_data = {...fresh_data, noPagerSet: true};
      if (router.query?.start) {
        fresh_data['pagerSetStartPage'] = router.query?.start;
      }
    }
    if (!data?.page_data) {
      fresh_data = {
        ...fresh_data,
        listings: [],
        ttlResults: data?.ttlResults,
        ttlPages: 0
      };
      listings = [];
    }
    if (data?.page_data) {
      fresh_data = {
        ...fresh_data,
        page_data: data.page_data,
        ttlResults: data?.ttlResults,
        ttlPages: data?.ttlPages
      };
      listings = data.page_data;
    }
    if (solrReq.is_submitting) {
      fresh_data = {...fresh_data, is_submitting: false};
    }
    setSolrReq({...solrReq, ...fresh_data});
  }

  useEffect(() => {
    document.body.classList.remove('not-search-body');
  }, []);

  const classes = useStyles();

  const noResultsMessage = (
      <Box p={4}>
        <Typography variant="h4" component="h2"><strong>No results
          found</strong></Typography>
        <Typography>Please reset the filters to continue searching.</Typography>
        <CustomMuiButton href="/rent/homes-for-rent" color="primary"
                         size="large" label={"Reset"}
                         stronglabel={"Search"}></CustomMuiButton>
      </Box>
  );

  return (
      <>
        <div className={classes.root}>
          <div className="alignfull listingWrapper">
            <ListingSearchContext.Provider value={{solrReq, setSolrReq}}>
              {(error) &&
              <Error/>
              }
              <Grid container spacing={3}>
                <Grid item xs>
                  <DynamicSearchForm type={'Rental'}
                                     cities={props?.cityFilterOpts}
                                     neighbors={props?.neighborFilterOpts}
                                     settOverride={newSettOverride}/>
                </Grid>
              </Grid>

              {(solrReq.is_submitting || solrReq.initial) &&
              <Spinner/>
              }
              <h1 className="sr-only">Eaton Realty Homes for Rent</h1>
              <Grid container spacing={0}>
                <Grid className={`outer-map-wrapper`} item>
                  <Hidden smDown>
                    <div className={`map-wrapper`}>

                      <div
                          className={`info-msg`}>Showing {solrReq.ttlResults < 501 ? solrReq.ttlResults : `500 out of ${solrReq.ttlResults}`} properties
                        in this area.<br/>Zoom the map to view more.
                      </div>
                      <MapWithAMarkerClusterer markers={listings}/>
                      <div className="legend-color">
                        <div className="item multiple">Multiple Properties</div>
                        <div className="item p-normal">Property for Rent</div>
                        <div className="item p-featured">Featured Property for
                          Rent
                        </div>
                      </div>
                    </div>
                  </Hidden>
                </Grid>
                <Grid item className="mapListingWrapper" id={`mapListingWrapper-rent`}>
                  <Box>
                    {solrReq?.page_data?.length > 0 && !isEmpty &&
                    <>
                      <Box px={1}>
                        {/*<InfiniteScrollPager scrollData={listings} gridCols={6}*/}
                        {/*                     nodeType={`listing`} layout={`card`}*/}
                        {/*                     perPage={12}>*/}
                        {/*</InfiniteScrollPager>*/}
                        <ListingsListComponent id={`homes-for-rent-wrap`} key={`homes-for-rent-wrap`}
                                               scrollTo={`mapListingWrapper-rent`}
                                               listings={listings} colMd={6}
                                               showPager={true}
                                               perPage={LISTINGS_PER_PAGE}
                                               pagerCurr={solrReq.curr_page}
                                               query_obj={query_obj}/>

                        <br/>
                        <div className={`smalltext descr`}>
                          <h3>Houses For Rent In Tampa</h3>
                          <h4>Search For Rental Homes In Specific Tampa
                            Neighborhoods</h4>
                          Hoping to reside in a specific neighborhood in Tampa?
                          You can refine the results in our home rental
                          directory for a specific neighborhood by entering the
                          name of the neighborhood into the search bar.
                          <br/><br/>
                          You can find homes for rent in almost any neighborhood
                          in Tampa, but certain neighborhoods may have more
                          homes available for rent than others. Some of the most
                          popular Tampa neighborhoods for renters include
                          Downtown Tampa, Old Seminole Heights, Harbour Island,
                          Channel District, and North Hyde Park. You can view a
                          full list of all of the neighborhoods in Tampa below.
                          <ul>
                            <li>Armenia Gardens Estates</li>
                            <li>Audubon Park</li>
                            <li>Ballast Point</li>
                            <li>Bayshore Beautiful</li>
                            <li>Bayshore Gardens</li>
                            <li>Bayside West</li>
                            <li>Beach Park</li>
                            <li>Beach Park Isles</li>
                            <li>Beasley</li>
                            <li>Bel Mar Shores</li>
                            <li>Bon Air</li>
                            <li>Carver City-Lincoln Gardens
                            </li>
                            <li>Channel District
                            </li>
                            <li>College Hill
                            </li>
                            <li>Culbreath Bayou
                            </li>
                            <li>Culbreath Heights
                            </li>
                            <li>Culbreath Isles
                            </li>
                            <li>Davis Islands
                            </li>
                            <li>Dixie Farms
                            </li>
                            <li>Downtown Tampa
                            </li>
                            <li>Drew Park
                            </li>
                            <li>Eastern Heights
                            </li>
                            <li>East Tampa
                            </li>
                            <li>East Ybor
                            </li>
                            <li>Fair Oaks - Manhattan Manor
                            </li>
                            <li>Fern Cliff
                            </li>
                            <li>Florence Villa
                            </li>
                            <li>Forest Hills
                            </li>
                            <li>Gandy-Sun Bay South
                            </li>
                            <li>Golf View
                            </li>
                            <li>Grant Park
                            </li>
                            <li>Gray Gables
                            </li>
                            <li>Hampton Terrace Historic District
                            </li>
                            <li>Harvey Heights
                            </li>
                            <li>Highland Pines
                            </li>
                            <li>Historic Hyde Park North
                            </li>
                            <li>Hyde Park
                            </li>
                            <li>Harbour Island
                            </li>
                            <li>Hunter's Green
                            </li>
                            <li>Interbay
                            </li>
                            <li>Live Oaks Square
                            </li>
                            <li>Lowry Park
                            </li>
                            <li>Lowry Park North
                            </li>
                            <li>Maryland Manor
                            </li>
                            <li>New Suburb Beautiful
                            </li>
                            <li>North Bon Air
                            </li>
                            <li>Northeast Community
                            </li>
                            <li>North Hyde Park
                            </li>
                            <li>North Tampa
                            </li>
                            <li>Northview Hills
                            </li>
                            <li>Oakford Park
                            </li>
                            <li>Oak Park
                            </li>
                            <li>Old Seminole Heights
                            </li>
                            <li>Palma Ceia
                            </li>
                            <li>Palma Ceia Pines
                            </li>
                            <li>Palma Ceia West
                            </li>
                            <li>Palmetto Beach
                            </li>
                            <li>Parkland Estates
                            </li>
                            <li>Port Tampa
                            </li>
                            <li>Rattlesnake
                            </li>
                            <li>Rembrandt Gardens
                            </li>
                            <li>Ridgewood Park
                            </li>
                            <li>River Arts District
                            </li>
                            <li>Riverbend
                            </li>
                            <li>Rivercrest
                            </li>
                            <li>River Grove
                            </li>
                            <li>Riverside Heights
                            </li>
                            <li>Robles Park
                            </li>
                            <li>Soho District
                            </li>
                            <li>South Seminole Heights
                            </li>
                            <li>Southeast Seminole Heights
                            </li>
                            <li>Southern Pines
                            </li>
                            <li>South Nebraska
                            </li>
                            <li>South Westshore
                            </li>
                            <li>Sulphur Springs
                            </li>
                            <li>Sunset Park
                            </li>
                            <li>Swann Estates
                            </li>
                            <li>Tampa Heights
                            </li>
                            <li>Tampa Overlook
                            </li>
                            <li>Tampa Palms
                            </li>
                            <li>Temple Crest
                            </li>
                            <li>Terrace Park
                            </li>
                            <li>University Square
                            </li>
                            <li>V.M. Ybor
                            </li>
                            <li>Virginia Park
                            </li>
                            <li>Wellswood
                            </li>
                            <li>West Hyde Park
                            </li>
                            <li>West Meadows
                            </li>
                            <li>West Tampa
                            </li>
                            <li>Water Street
                            </li>
                            <li>West Riverfront
                            </li>
                            <li>Westshore Palms
                            </li>
                            <li>Woodland Terrace
                            </li>
                            <li>Ybor City</li>
                          </ul>

                          <h3>Search For Rental Houses By Zip Code In Tampa</h3>
                          To hyperfocus your rental search, you can search homes
                          for rent by zip code. Simply enter the zip code into the search bar on our
                          directory and hit the search button to see all available houses for rent in that specific zip code.
                          <br/>
                          It’s important to note that the rental market is very
                          competitive. Tampa remains one of the most desirable places to live
                          in the United States. Time Magazine recently designated the city as <a
                            href={`https://time.com/collection/worlds-greatest-places-2023/6261491/tampa-florida/`}
                            target={`_blank`}>one of the greatest places in the
                          world</a>.
                          With such a high demand for rentals, the supply in any
                          zip code can be low. You may have trouble finding rentals available if you
                          only look at a handful of zip codes. Nevertheless, all of the zip codes you can search in Tampa can be found below.
                          <br/>
                          <ul className={`cols-3`}>
                            <li>33602</li>
                            <li>33603</li>
                            <li>33604</li>
                            <li>33605</li>
                            <li>33606</li>
                            <li>33607</li>
                            <li>33609</li>
                            <li>33610</li>
                            <li>33611</li>
                            <li>33612</li>
                            <li>33613</li>
                            <li>33614</li>
                            <li>33615</li>
                            <li>33616</li>
                            <li>33617</li>
                            <li>33618</li>
                            <li>33619</li>
                            <li>33620</li>
                            <li>33621</li>
                            <li>33624</li>
                            <li>33625</li>
                            <li>33626</li>
                            <li>33629</li>
                            <li>33633</li>
                            <li>33634</li>
                            <li>33635</li>
                            <li>33637</li>
                            <li>33647</li>
                          </ul>
                          <h3>How To Rent A Home In Tampa With Eaton</h3>
                          Nearly all of the properties listed for rent on our rental directory are managed by us. Renting with Eaton Realty will be one of the best rental experiences you’ve ever had. Our property management team is extremely responsive, and we’ve implemented an impressive stack of technologies to make renting with Eaton as easy as possible.
                          <br/><br/>
                          The process of renting a home with Eaton Realty can ultimately be broken down into two steps: application and move-in. We’ll explain both phases of the process below.

                          <h4>Apply To Rent</h4>
                          Before you can rent a home with Eaton, you must apply to rent. When you click on a listing of a house for rent, you’ll see a yellow “Apply Now” button. You can use the apply now link to fill out and submit an application for rental.
                          <br/><br/>
                          You’ll need documents for the application, including paystubs or a document verifying employment, bank statements, and a photo of your driver’s license, so make sure to gather these items as you start the application process.
                          <br/><br/>
                          If you’re currently renting, you’ll need to have your landlord complete a rental verification. If you have pets, you must also complete a pet screening through petscreening.com.
                          <br/><br/>
                          Once you submit your application, we’ll review it and start the approval process. We will obtain credit reports, run a criminal background check, review your rental history, and verify your employment.

                          <h4>Move Into Your Rental</h4>
                          If your application is approved, we’ll prepare the lease so you can review and sign. We’ll send you the lease to sign electronically and email you the move-in instructions.
                          On the day of the move, a member of our property management team will meet you at the property to deliver the keys to the home and show you the features and systems of the home.
                          <br/><br/>
                          Our service doesn’t end once you move in. You can continue to communicate with our property management team through the rental portal we provide to have any questions or issues resolved by a team member.

                          <h3>Tampa Rent Prices & Average Rent</h3>
                          Rents in Tampa will vary greatly depending on the location of the rental, the amenities of the rental, the size of the rental, and several other factors. The average rent in Tampa is $1,915 per month,
                          which is on par <a href={`https://www.rent.com/research/average-rent-price-report`} target={`_blank`}>with the national average of $1,995</a>.
                          <br/><br/>
                          If you’d like to learn more about the Tampa rental market and rent prices, we have <a href={`/blog/property-management/average-rent-tampa-prices-neighborhood`} target={`_blank`}>a post on the average rent in Tampa</a> with a breakdown of rents by neighborhood. You can research the neighborhoods with the lowest rents, highest rents, and more.

                          <h3>Other Cities Near Tampa To Consider Renting In</h3>
                          Not finding what you’re looking for in Tampa? Limiting yourself to one city can make it harder to find rental homes that meet your needs and your budget. The larger your search radius is, the easier it will be to find the perfect rental. You may want to consider expanding your search to cities that neighbor Tampa. Cities close to Tampa include:
                          <ul>
                            <li><a href={`/rent/apollo-beach`} target={`_blank`}>Apollo Beach</a></li>
                            <li><a href={`/rent/brandon`} target={`_blank`}>Brandon</a></li>
                            <li><a href={`/rent/dover`} target={`_blank`}>Dover</a></li>
                            <li><a href={`/rent/gibsonton`} target={`_blank`}>Gibsonton</a></li>
                            <li><a href={`/rent/lithia`} target={`_blank`}>Lithia</a></li>
                            <li><a href={`/rent/plant-city`} target={`_blank`}>Plant City</a></li>
                            <li><a href={`/rent/riverview`} target={`_blank`}>Riverview</a></li>
                            <li><a href={`/rent/ruskin`} target={`_blank`}>Ruskin</a></li>
                            <li><a href={`/rent/seffner`} target={`_blank`}>Seffner</a></li>
                            <li><a href={`/rent/temple-terrace`} target={`_blank`}>Temple Terrace</a></li>
                            <li><a href={`/rent/thonotossassa`} target={`_blank`}>Thonotossassa</a></li>
                            <li><a href={`/rent/valrico`} target={`_blank`}>Valrico</a></li>
                            <li><a href={`/rent/wimauma`} target={`_blank`}>Wimauma</a></li>
                          </ul>

                          <h3>Tampa House Rental FAQs</h3>

                          <h4>Are All Of The Homes On The Rental Directory Currently Available For Rent?</h4>
                          Our directory of houses for rent in Tampa doesn’t just include vacant properties. The directory includes all the rental properties we manage regardless of their current availability status. Each rental contains the availability status at the top of the listing.
                          <br/><br/>
                          Homes currently available for rent have a yellow box at the top of the listing that says “Confirmed Available” with the availability date. Homes that aren’t available have a grey box at the top of the listing that displays the lease expiration date.
                          <br/><br/>
                          We display all of our rentals in the directory so that you can search ahead and see homes that may become available for rent in the future. You can favorite rentals you like to check back on them and see if the existing lease gets renewed or not; we typically know about 60 days in advance. If you only want to see homes currently available for rent, you can modify the filters to only show rentals that are confirmed available.

                          <h4>What Should I Do Next If I’ve Found A Rental I’m Interested In?</h4>
                          If you find a rental you’re interested in, the next step in the rental process is to contact <a href={`/rent`} target={`_blank`}>our Tampa Bay rental realtors</a>.
                          Our realtors can help you schedule a showing to see the rental unit and help you apply for the rental if you like the home.

                          <h4>Is There A Way To Search For Specific Kinds Of Rental Homes?</h4>
                          The directory includes several different search filters you can use to find the right rental home for you. You’ll notice filters for price and bed and bathroom quantities above the map. You can also view more filters with the “More” button. You can search rental properties by property type, including:
                          <ul>
                            <li><a href={`https://www.eatonrealty.com/rent/homes-for-rent?pt=1&sort=DESC_feat`} target={`_blank`}>Condos for rent in Tampa</a></li>
                            <li><a href={`https://www.eatonrealty.com/rent/homes-for-rent?pt=2&sort=DESC_feat`} target={`_blank`}>Multi-family homes for rent in Tampa</a></li>
                            <li><a href={`https://www.eatonrealty.com/rent/homes-for-rent?pt=3&sort=DESC_feat`} target={`_blank`}>Single-family homes for rent in Tampa</a></li>
                            <li><a href={`https://www.eatonrealty.com/rent/homes-for-rent?pt=4&sort=DESC_feat`} target={`_blank`}>Townhouses for rent in Tampa</a></li>
                            <li><a href={`https://www.eatonrealty.com/rent/homes-for-rent?pt=5&sort=DESC_feat`} target={`_blank`}>Villas for rent in Tampa</a></li>
                          </ul>
                          <br/>
                          You can also search for specific features, like homes for rent in Tampa with a pool and waterfront homes for rent in Tampa.
                          <br/><br/>

                          Property information is provided exclusively for
                          consumers&apos; personal, non-commercial use and may
                          not be used for any purpose other than to identify
                          prospective properties consumers may be interested in purchasing.
                          Data is deemed reliable but is not guaranteed. Data courtesy Stellar MLS as
                          of {new Date().toUTCString()}
                        </div>
                      </Box>
                    </>
                    }
                    {(!solrReq.ttlResults || !solrReq.ttlPages) &&
                    <NoResultsMessage message={noResultsMessage}/>
                    }
                    <Box mt={3}>
                      <GlobalFooter/>
                    </Box>
                  </Box>

                </Grid>
              </Grid>
            </ListingSearchContext.Provider>
          </div>
        </div>
      </>
  );
}

export default HomesForRentPage;
